import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Unlock } from "lucide-react";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { Card } from "DS/custom/card";
import { Switch } from "DS/ui/switch";
import { Textarea } from "DS/ui/textarea";
import { AlertDialogFooter } from "DS/ui/alert-dialog";
import { Form, FormControl, FormField, FormItem, FormLabel } from "DS/ui/form";

import { APPROVED, REFUSED } from "Config/Qualification";

/** @internal */
export const MUTATION = gql`
  mutation BlockSupplierMutation($id: ID!, $block: Boolean!, $inactivate: Boolean, $blockedReason: String) {
    blockSupplier(input: { id: $id, block: $block, inactivate: $inactivate, blockedReason: $blockedReason }) {
      supplier {
        id
        blockedBy
        blockedAt
        blockedReason
        inactivatedAt
      }
    }
  }
`;

const FORM_SCHEMA = z.object({
  inactivate: z.boolean(),
  blockedReason: z.string().optional(),
});

function BlockSupplierMutation({ unblock, inactivatedAt, state, onClose }) {
  const { id } = useParams();
  const [blockSupplier, { loading }] = useMutation(MUTATION);

  const form = useForm({
    resolver: zodResolver(FORM_SCHEMA),
    defaultValues: { inactivate: !inactivatedAt, blockedReason: "" },
  });

  const onClick = async (block) => {
    const { blockedReason, inactivate } = form.getValues();
    await blockSupplier({ variables: { id, blockedReason, block, inactivate } });
    window.location.reload();
  };

  if (unblock)
    return (
      <Button type="button" variant="ghost" disabled={loading} onClick={() => onClick(false)}>
        <Unlock className="mr-2 h-4 w-4" /> Desbloquear homologação
      </Button>
    );

  const canUpdateInactivatedAt = [APPROVED, REFUSED].includes(state) && !inactivatedAt;

  return (
    <Form {...form}>
      <form
        id="BlockSupplierMutation"
        className="flex flex-col gap-3"
        onSubmit={form.handleSubmit(() => onClick(true))}
      >
        <FormField
          control={form.control}
          name="blockedReason"
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="blockedReason" className="text-sm font-medium">
                Por que você está bloqueando este fornecedor? (opcional)
              </FormLabel>

              <FormControl>
                <Textarea
                  {...field}
                  className="h-40"
                  id="blockedReason"
                  onKeyDown={(e) => e.stopPropagation()}
                  placeholder="Digite quais pendências gostaria que sejam regularizadas"
                />
              </FormControl>
            </FormItem>
          )}
        />

        {canUpdateInactivatedAt ? (
          <Card>
            <FormField
              name="inactivate"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel htmlFor="inactivate" className="text-sm font-medium">
                    Deseja inativar o fornecedor?
                  </FormLabel>

                  <FormControl>
                    <div className="flex flex-row items-center justify-between gap-8">
                      <Text size="small">
                        Ao inativar um fornecedor, não será permitida nenhuma atualização de suas informações.
                      </Text>

                      <Switch {...field} id="inactivate" checked={field.value} onCheckedChange={field.onChange} />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </Card>
        ) : null}

        <AlertDialogFooter>
          <Button type="submit" disabled={loading} variant="destructive" form="BlockSupplierMutation">
            Bloquear
          </Button>

          <Button type="button" variant="outline" onClick={onClose} disabled={loading}>
            Cancelar
          </Button>
        </AlertDialogFooter>
      </form>
    </Form>
  );
}

BlockSupplierMutation.defaultProps = {
  state: "",
  unblock: false,
  inactivatedAt: "",
};

BlockSupplierMutation.propTypes = {
  unblock: PropTypes.bool,
  state: PropTypes.string,
  inactivatedAt: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default BlockSupplierMutation;
