import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Lock, Network, AlertTriangle, CircleDollarSign, Tag } from "lucide-react";

import { cn } from "DS/lib/utils";
import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";
import { TableRow, TableCell } from "DS/ui/table";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

import RatingIcon from "Molecules/Icon/RatingIcon";

import { STATES_NAME as SUPPLIER_STATES_NAME } from "Config/Supplier";
import { STATES_NAME as QUALIFICATION_STATES_NAME } from "Config/Qualification";

function SupplierTableRow({ supplier, supplierPaymentStripeLink }) {
  const { id, inactivatedAt, rating, blockedAt, ratingLabel, tags } = supplier;
  const { regularization, pendingPayment, qualification } = supplier;

  const { withWorkflow } = qualification;

  const tooltipTags = () => (
    <ul className="divide-y">
      {_.sortBy(tags, "displayName").map((tag) => (
        <li key={tag.id} className={cn(tags.length > 1 ? "py-1" : "")}>
          {tag.displayName}
        </li>
      ))}
    </ul>
  );

  return (
    <TableRow key={id}>
      <TableCell className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-1.5 max-w-sm">
          <WrappedTooltip message={inactivatedAt ? "Fornecedor inativo" : "Fornecedor ativo"}>
            <div className={cn("h-2 w-2 flex-none rounded-full", inactivatedAt ? "bg-red-500" : "bg-green-500")} />
          </WrappedTooltip>

          <Anchor href={`/buyers/suppliers/${id}/panel`}>{supplier.name}</Anchor>
        </div>

        <Text size="xsmall" color="secondary" className="ml-3 pl-0.5">
          {supplier.identifierMasked}
        </Text>
      </TableCell>

      <TableCell>
        <div className="flex flex-row  items-center gap-2">
          <RatingIcon ratingLabel={ratingLabel} rating={rating} />

          <div className="flex flex-col">
            <Text size="small" color="secondary">
              {supplier.displayCategory}
            </Text>

            <Text size="xsmall" color="secondary">
              iniciado por {supplier.addedBy.email}
            </Text>
          </div>
        </div>
      </TableCell>

      <TableCell>
        <Text size="small" color="secondary">
          {SUPPLIER_STATES_NAME[supplier.state]}
        </Text>
      </TableCell>

      <TableCell>
        <Text size="small" color="secondary">
          {QUALIFICATION_STATES_NAME[qualification.state]}
        </Text>
      </TableCell>

      <TableCell>
        <div className="flex flex-row gap-4">
          <WrappedTooltip message={blockedAt ? "Fornecedor bloqueado" : "Fornecedor não bloqueado"}>
            <Lock size={16} className={blockedAt ? "text-destructive" : "text-slate-300"} />
          </WrappedTooltip>

          <WrappedTooltip
            message={withWorkflow ? "Decisão por fluxo de aprovação" : "Nenhum fluxo de aprovação iniciado"}
          >
            <Network
              size={16}
              weight={withWorkflow ? "fill" : "regular"}
              className={withWorkflow ? "text-blue-600" : "text-slate-300"}
            />
          </WrappedTooltip>

          <WrappedTooltip message={regularization ? "Fornecedor em regularização" : "Fornecedor regular"}>
            <AlertTriangle
              size={16}
              weight={regularization ? "fill" : "regular"}
              className={regularization ? "text-orange-400" : "text-slate-300"}
            />
          </WrappedTooltip>

          <WrappedTooltip message={tags.length ? tooltipTags() : "Nenhum marcador adicionado"}>
            <Tag
              size={16}
              weight={tags.length ? "fill" : "regular"}
              className={tags.length ? "text-blue-400" : "text-slate-300"}
            />
          </WrappedTooltip>

          {supplierPaymentStripeLink ? (
            <WrappedTooltip message={pendingPayment ? "Pagamento pendente" : "Pagamento realizado"}>
              <CircleDollarSign size={16} className={pendingPayment ? "text-destructive" : "text-green-500"} />
            </WrappedTooltip>
          ) : null}
        </div>
      </TableCell>
    </TableRow>
  );
}

SupplierTableRow.defaultProps = { supplierPaymentStripeLink: "" };

SupplierTableRow.propTypes = {
  supplierPaymentStripeLink: PropTypes.string,
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    rating: PropTypes.number,
    stateI18n: PropTypes.string,
    blockedAt: PropTypes.string,
    legalEntity: PropTypes.string,
    ratingLabel: PropTypes.string,
    regularization: PropTypes.bool,
    pendingPayment: PropTypes.bool,
    inactivatedAt: PropTypes.string,
    displayCategory: PropTypes.string,
    identifierMasked: PropTypes.string,
    stateUpdatedAtI18n: PropTypes.string,
    addedBy: PropTypes.shape({ email: PropTypes.string }),
    qualification: PropTypes.shape({
      id: PropTypes.string,
      state: PropTypes.string,
      withWorkflow: PropTypes.bool,
      workflows: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    }),
    tags: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, displayName: PropTypes.string })),
  }).isRequired,
};

export default SupplierTableRow;
